import React from 'react';
import { useRoutes, BrowserRouter } from 'react-router-dom';

import Home from '../Home/index';
import Domiciliarios from '../Domiciliario/index';
import Tiendas from '../Tiendas/index';
import Login from '../Login/index';
import CreateAconunt from '../CreateAcount/index';

import './index.css';

const AppRouters = () => {
    let routes = useRoutes([
        { path: '/', element: <Home /> },
        { path: '/Domiciliarios', element: <Domiciliarios /> },
        { path: '/Tiendas', element: <Tiendas /> },
        { path: '/Login', element: <Login /> },
        { path: '/CreateAconut', element: <CreateAconunt /> },
    ]);
    return routes;
}

const App = () => {
    return (
        <BrowserRouter>
            <AppRouters />
        </BrowserRouter>
    );
}

export default App;