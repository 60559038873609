import React from 'react';
import Layout from '../Components/Layout/index';
import Footer from '../Components/Footer/index';
import './index.css';

const CreateAccount = () => {
  return (
    <div>
      <Layout/>
      <main className="contenedor-form-creat-home">

      <div className="titulo-home-creat-home">

      <h1>¡Bienvenido a Barrio YA!</h1>
      
      <p>Por favor, completa los siguientes campos para crear tu cuenta y empezar a utilizar nuestros servicios.</p>
      
      </div>
      
      <div className="login-container-creat-home">

      <form className="login-form-creat-home">
            <label htmlFor="nombre">Nombre Completo</label>
            <input type="text" id="nombre" placeholder="Juan Pérez" required />
            
            <label htmlFor="email">Correo Electrónico</label>
            <input type="email" id="email" placeholder="juanperez@softmark.ai" required />
            
            <label htmlFor="direccion">Direccion</label>
            <input type="text" id="direccion" placeholder="calle 00 " required />

            <label htmlFor="telefono">Numero Telefonico</label>
            <input type="number" id="telefono" placeholder="310 0000000" required />
            
            <label htmlFor="password">Contraseña</label>
            <input type="password" id="password" placeholder="***********" required />
            
            <label htmlFor="confirm-password">Confirmar Contraseña</label>
            <input type="password" id="confirm-password" placeholder="***********" required />
            
            <label className="checkbox-container">
              <input type="checkbox" required /> Acepto los <a href="/terms" className='termino-condiciones'>Términos y Condiciones</a>
            </label>
            
            
            
            <button className="button-login-creat-home" type="submit">Crear Cuenta</button>
          </form>
    
    </div>

    </main>
      <Footer/>
    </div>
  );
};

export default CreateAccount;