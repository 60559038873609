import React from 'react';
import Layout from '../Components/Layout/index';
import Footer from '../Components/Footer/index';
import { Link } from 'react-router-dom';
import './index.css';

const Login = () => {
  return (
    <div>
      <Layout />
      <main className="contenedor-form-home">
        <div className="titulo-home">
          <h1>¡Bienvenido de nuevo! Inicia sesión para continuar</h1>
          <p>
            Ingresa tu correo electrónico y contraseña para acceder a tu cuenta. Si aún no tienes una cuenta, puedes <Link className="navbar-item-home-createAcount" to="/CreateAconut">crear una aquí</Link>.
          </p>
        </div>
        <div className="login-container-home">
          <form className="login-form-home">
            <label htmlFor="email">Correo Electrónico</label>
            <input type="email" id="email" placeholder="BarrioYA@gmail.com" required />
            <label htmlFor="password">Contraseña</label>
            <input type="password" id="password" placeholder="***********" required />
            <a href="/forgot-password" className="forgot-password-home">Olvidé mi contraseña</a>
            <button className="button-login-home" type="submit">Iniciar Sesión</button>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Login;
